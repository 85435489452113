import React, { useEffect, useRef, useState } from "react"
import Sketch from '../lib/sketch';
import { isIpad, isIE11 } from '../utils/utilities';

interface Props {
    image: string,
    map: string,
    // enabled: boolean,
    horizontalThreshold: number,
    verticalThreshold: number,
    className?: string
    children: any
}

const Background3d = ({ children, className, image, map, horizontalThreshold, verticalThreshold}: Props) => {
    const backgroundRef = useRef<HTMLDivElement>(null);
    const [sketchObject, setSketchObject] = useState(null);
    useEffect(() => {
        if (backgroundRef && backgroundRef.current && !sketchObject && !isIpad && !isIE11) {
            setSketchObject(new Sketch(backgroundRef.current, image, map, horizontalThreshold, verticalThreshold, !isIpad));
        }
    },[sketchObject, backgroundRef, setSketchObject]);

    useEffect(() => {
        return(() => {
            if (sketchObject) {
                sketchObject.destroy();
            }
        })
    });
    return (
        <div className={ `background-3d ${ className ? className : '' }` }>
            <div ref={ backgroundRef }  className="background-3d__background">
                { (isIpad || isIE11) && <img src={ image } alt="KV"/> }
            </div>
            { children }
        </div>
    )
}

export default Background3d
