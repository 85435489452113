import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { navigate } from "gatsby"
import KVBG from '../images/kv-bg.png';
import KVMAP from '../images/kv-map.png';
import Background3D from '../components/background3d';
import CQLogo from '../components/cqlogo';
import Logo from '../images/adobe-logo.inline.svg';
import { Helmet } from "react-helmet";
import { getContinueLink } from '../actions/quiz';
import createjs from 'preload-js';
import TransitionLink from '../components/transitionLink'
import Loader from '../components/loader';
import FooterPolicies from '../components/footer-policies';

import {
  GlobalStateContext,
  GlobalDispatchContext  
} from "../context/GlobalContextProvider"

interface ButtonsProps {
    pageContext: Context
    started: boolean
    completed: boolean
    onStart: () => void
    onRestart: () => void
    onContinue: () => void
}

const Buttons = ({ started, completed, onStart, onRestart, onContinue, pageContext }:ButtonsProps) => {
  const { translation } = pageContext;
  const [ extraClass, setExtraClass] = useState("");

  useEffect(() => {
    if (started && !completed || completed) {
      setExtraClass("button--twin");
    } else {
      setExtraClass("");
    }
  },[started, completed]);
  if (started && !completed) {
    return (<div className="index__buttons">                  
      <button key="continue-1" onClick={ () => onContinue() } className={`button ${extraClass}`}><span>{ translation.general.continue }</span></button>
      <button key="restart-1" onClick={ () => onRestart() } className={`button ${extraClass}`}><span>{ translation.general.restart }</span></button>
    </div>)
  } else{
    if (completed) {
      return (<div className="index__buttons">                  
        <button key="result-3" onClick={ () => onContinue() } className={`button ${extraClass}`}><span>{ translation.general.result }</span></button>
        <button key="restart-2" onClick={ () => onRestart() } className={`button ${extraClass}`}><span>{ translation.general.restart }</span></button>
      </div>)
    } else {
      return <div className="index__buttons"><button key="take-the-test" onClick={ () => onStart() } className="button"><span>{ translation.general.take_the_test }</span></button></div>;
    }
  }
};

interface Props {
    pageContext: Context
    location: any
}
type Context = {
    settings: any
    langCode: string
    lang: string
    title: string
    route: string
    translation: any
    questions: any
}

const IndexPage = ({ pageContext, location }: Props) => {
    const { title, translation, route, questions, lang, langCode, settings } = pageContext;
    const dispatch = useContext(GlobalDispatchContext)
    const state = useContext(GlobalStateContext)
    const [progress, setProgress] = useState(0);
    const { started } = state;
    const [initialStarted, setInitialStarted] = useState(started);
    const buttonsRef = useRef(null);
    const backgroundRef = useRef(null);
    const startTest = async () => {
      await dispatch({
        type: 'START_TEST',
        questions: questions
      });
      await dispatch({
        type: 'SHUFFLE_OPTIONS'
      });      
      setTimeout(()=> {
        document.body.classList.add('culture');
      },200);
      document.documentElement.classList.remove('in');
      setTimeout(()=> {
        navigate(`${route}question/1`)
      }, 600);
    }
    const continueTest = async () => {
        document.documentElement.classList.remove('in');      
        setTimeout(()=> {
            navigate(getContinueLink(route, state.answers, state.completed, state.currentQuestion));
        }, 600);
    }
    const restartTest = async () => {
      await dispatch({
        type: 'RESTART_TEST',
        questions: questions
      });
      document.documentElement.classList.remove('in');
      setTimeout(()=> {
        setInitialStarted(false);
        navigate(`${route}`)
        document.documentElement.classList.add('in');
      }, 600);
    }
    const LoadButtons = () => {
        return (<Buttons pageContext={pageContext} completed={state.completed} onStart={startTest} onContinue={continueTest} onRestart={restartTest} started={ initialStarted }/>);
    };

    const renderBackground = useMemo(() => {
      return (<Background3D 
        image={KVBG}
        map={KVMAP}
        horizontalThreshold={85}
        verticalThreshold={85}>
          
          <div className="index__logo">
              <div className="index__logo-shadow">
                  <CQLogo lang={ lang }/>
                  <div className="h1 index__title" dangerouslySetInnerHTML={{ __html: translation.general.discover_your_creative_potential_to_lead }}/>
              </div>
              <div className="index__logo-main">
                    <CQLogo lang={ lang }/>
                  <h1 className="index__title" dangerouslySetInnerHTML={{ __html: translation.general.discover_your_creative_potential_to_lead }}></h1>
              </div>
          </div>
          </Background3D>);
    },[KVBG, KVMAP]);
    const handleProgress = (data:any) => {
        
    }
    const handleComplete = (data:any) => {
        setProgress(1);
        // document.documentElement.classList.add('in');
    }
    useEffect(() => {
        setProgress(0.8);
        const preload = new createjs.LoadQueue();
        preload.loadFile(KVBG);
        preload.loadFile(KVMAP);
        preload.addEventListener("progress", handleProgress);
        preload.addEventListener("complete", handleComplete);
        preload.load();
    },[]);
    const FooterContent = () => (<>
        <TransitionLink className="link footer__link footer__link-left" to={`${route}note-from-our-authors`}><span>{ translation.general.authors_note }</span></TransitionLink>
        <FooterPolicies translation={translation}/>
        <a href={ translation.general.adobe_link } target="_blank" className="footer__logo" aria-label={ translation.general.go_to_adobe_website }><Logo/></a>
    </>);
    return (
        <Layout pathname={ location.pathname} socialLinks={settings.social_media}  lang={ lang } route={route} translation={ translation } footerContent={FooterContent}>
          <Helmet bodyAttributes={{ class: `index-page lang-${lang}` }}>
            <link rel="preload" href={KVBG} as="image"/>
            <link rel="preload" href={KVMAP} as="image"/>          
          </Helmet>
          <SEO lang={langCode} title={ title } />
          <Loader lang={lang} progress={ progress } onComplete={ () => document.documentElement.classList.add('in') } />
          <div className={ `index`}>
            <div className="container">
                <div className="index__content">
                  <div ref={backgroundRef} className="index__background">
                    { renderBackground }
                  </div>
                    { LoadButtons() }   
                </div>
            </div>
          </div>
        </Layout>
      );
  }

export default IndexPage
