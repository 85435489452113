import React, { useEffect, useRef } from "react"
import LoaderIcon from '../images/loader.inline.svg'
import LoaderCNIcon from '../images/loader-cn.inline.svg'

interface Props {
    progress:number
    lang?: string
    onComplete?: () => void
}
const Loader = ({ progress, lang='', onComplete } : Props) => {
    const loaderRef = useRef(null)
    useEffect(() => {
        if (progress >= 1 ) {
            document.documentElement.classList.add('loaded');
            
            if (onComplete) {        
                setTimeout(() => {
                    onComplete();
                }, 1000);
                
            }
        }
        if (loaderRef.current) {
            loaderRef.current.querySelector('.loader__clip').style.transform = `scaleX(${progress})`;
        }
    }, [progress, loaderRef])
    if (lang === 'cn') {
        return (
            <div ref={ loaderRef } className="loader">
                <LoaderCNIcon />
            </div>
            )
    } else {
        return (
            <div ref={ loaderRef } className="loader">
                <LoaderIcon />
            </div>
            )
    }
}

export default Loader
